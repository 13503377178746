import React from 'react';
import '../styles/Background.scss';

export function Background() {
  return (
    <>
      <div className='background'></div>
    </>
  );
}
